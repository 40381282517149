<template>
<div style="text-align: center;font-size:1rem;">
  <span>锁屏页面</span>
  <input v-model="password"/>
  <button @click='lockscreen'>解锁</button>
</div>
</template>
<script>
export default {
  data() {
    return {
       password:'',
    };
  },
  methods:{
    lockscreen(){
      console.log(sessionStorage.getItem('isLock'),this.password)
      if(this.password == sessionStorage.getItem('isLock')){
        sessionStorage.removeItem('isLock');
        this.$router.push({path:'/home'})
      }else{
        console.log('密码错误')
      }
    }
  }
};
</script>
<style scoped>
  div{
    text-align: center;
    font-size: 1rem;
    margin-top: 10%;
  }
</style>